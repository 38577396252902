package chart

import ein2b.core.log.log
import ein2b.core.view.displayBlock
import ein2b.core.view.router.eDomPage
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import app.EntInit
import org.w3c.dom.HTMLElement

class ChartP(router:eRouter<HTMLElement>):eDomPage(::ChartView, router){
    override suspend fun adding(data:eRouterData){ log("${data.key} adding") }
    override suspend fun restoring(data:eRouterData){ log("${data.key} restoring") }
    override suspend fun renew(){ log("${data.key} renew") }
    override suspend fun shown(data:eRouterData){
        view?.entity(EntInit)
        view?.displayBlock()
    }
}