import chart.ChartP
import ein2b.core.core.Now
import ein2b.core.core.err
import ein2b.core.core.jsNow
import ein2b.core.view.eDomBinder
import ein2b.core.view.eView
import ein2b.core.view.router.eDomBase
import ein2b.core.view.router.eHashManager
import ein2b.core.view.router.eStaticRouter
import kotlinx.browser.document
import org.w3c.dom.HTMLElement

suspend fun main(){
    Now.instance = jsNow
    eView.binder = eDomBinder().apply{ start() }
    hashManager.init()
}
val hashManager = eHashManager("chart").apply{
    val baseEl = document.querySelector("#base") as? HTMLElement ?: err("invalid #base")
    addRouters(
        eStaticRouter(eDomBase(baseEl)).apply{
            addFactories(
                "chart" to { router -> ChartP(router) }
            )
        }
    )
}