package app

import ein2b.core.entity.eEntity

object EntInit:eEntity()
class EntChartOnce:eEntity(){
    var tempInfo:EntTempInfo by entity(::tempInfo, ::EntTempInfo)//온도정보
}
class EntChartList:eEntity(){
    var startDate:String by string(::startDate) //2022-01-10 13:50:00
    var endDate:String by string(::endDate) //2022-01-10 13:50:00
    var tempInfoList:MutableList<EntTempInfo> by entityList(::tempInfoList, ::EntTempInfo){ default(mutableListOf(), false) }
}

class EntTempInfoLog:EntTempInfo(){
    var eventType: MutableList<Int> by intList(::eventType) //{validator(ValiTemperature)}  // 0, 1
    var buttonCode: MutableList<Int> by intList(::buttonCode) //{validator(ValiTemperature)}  // 0:보통, 29:시작, 25:끝
    var buttonAction: MutableList<Int> by intList(::buttonAction) //{validator(ValiTemperature)}  // 0, 1:유효한 값
    //버튼 코드 + 버튼 액션 : 29+1부터 25+1까지가 하나의 레코드
    //그리고 이벤트 1 온도 0.0은 정상 온도가 아님

    fun toIterator() = object: Iterator<TempInfoLog> {
        private val iDate = date.iterator()
        private val iTemp = temp.iterator()
        private val iEventType = eventType.iterator()
        private val iButtonCode = buttonCode.iterator()
        private val iButtonAction = buttonAction.iterator()

        override fun hasNext(): Boolean =
            iDate.hasNext() && iTemp.hasNext() && iEventType.hasNext() && iButtonCode.hasNext() && iButtonAction.hasNext()

        override fun next(): TempInfoLog = TempInfoLog(
            dateLocal = iDate.next(),
            temp = iTemp.next(),
            eventType = EnumTempInfoEvent(iEventType.next()),
            buttonCode = EnumTempInfoButton(iButtonCode.next()),
            buttonAction = EnumTempInfoButtonAction(iButtonAction.next())
        )
    }

    data class TempInfoLog(val dateLocal: Long, val temp: Int, val eventType: EnumTempInfoEvent, val buttonCode: EnumTempInfoButton, val buttonAction: EnumTempInfoButtonAction):Comparable<TempInfoLog> {
        val entryType: String
            get() = when {
                eventType==EnumTempInfoEvent.EVENT -> "IGNORED(EVENT)"
                buttonCode!=EnumTempInfoButton.NOBUTTON -> {
                    if(buttonAction==EnumTempInfoButtonAction.ACTION) {
                        if(buttonCode==EnumTempInfoButton.START)
                            "START"
                        else if(buttonCode==EnumTempInfoButton.END)
                            "END"
                        else
                            "IGNORED(BUTTON='')"
                    } else {
                        "IGNORED(BUTTON=${buttonCode.code})"
                    }
                }
                eventType==EnumTempInfoEvent.NORMAL && buttonCode==EnumTempInfoButton.NOBUTTON && buttonCode==EnumTempInfoButton.NOBUTTON -> "NORMAL"
                else -> "IGNORED"
            }

        fun compareEvent(o: TempInfoLog): Int =
            if(eventType==EnumTempInfoEvent.EVENT && o.eventType!=EnumTempInfoEvent.EVENT) -1
            else if(eventType!=EnumTempInfoEvent.EVENT && o.eventType==EnumTempInfoEvent.EVENT) 1
            else 0

        fun compareKeyCode(o:TempInfoLog): Int =
            if(buttonCode!=EnumTempInfoButton.NOBUTTON) { // 나는 버튼
                if(o.buttonCode!=EnumTempInfoButton.NOBUTTON) // 나도 남도 버튼인 경우
                    -buttonCode.code + o.buttonCode.code  // end(25)가 start(29)보다 더 뒤에 와야 함
                else
                    +1 // 내가 버튼인데 남은 버튼이 아닌 경우 내가 더 큼
            } else {  // 나는 버튼이 아님
                if(o.buttonCode!=EnumTempInfoButton.NOBUTTON)
                    -1 // 나는 버튼이 아닌데 남은 버튼
                else
                    0  // 나도 남도 버튼이 아닌 경우
            }

        override fun compareTo(o: TempInfoLog): Int =
            if(dateLocal==o.dateLocal) {
                val evCmp = compareEvent(o)
                if(evCmp==0)
                    compareKeyCode(o)
                else
                    evCmp
            } else
                (dateLocal - o.dateLocal).compareTo(0)
    }
    open class TempInfoLogException: RuntimeException()
}

// 온도정보
open class EntTempInfo:eEntity(){
    class DateFormat(val date:Long){
        var year:Int = 0
        var month:Int = 0
        var day:Int = 0
        var hour:Int = 0
        var min:Int = 0
        var sec:Int = 0
        init{
            var t = date
            sec = (t % 100).toInt(); t /= 100
            min = (t % 100).toInt(); t /= 100
            hour = (t % 100).toInt(); t /= 100
            day = (t % 100).toInt(); t /= 100
            month = (t % 100).toInt(); t /= 100
            year = t.toInt()
        }
        fun toDate():String = "$year-${plusZero(month)}-${plusZero(day)} ${plusZero(hour)}:${plusZero(min)}:${plusZero(sec)}"
        private fun plusZero(v:Int):String = "00$v".let{ it.substring(it.length-2, it.length) }
    }
    var cnt: Int by int(::cnt){}    // 전체 데이터 갯수
    var date: MutableList<Long> by longList(::date) //{validator(ValiLongDateString)}  // 날짜
    var temp: MutableList<Int> by intList(::temp) //{validator(ValiTemperature)}  // 온도

    // 주의: date는 UTC기준으로 이미 변환이 되어 있어야 함
    fun toDbStr():String{
        require(cnt>=0 && cnt==date.size && cnt==temp.size){"배열 크기가 같아야 합니다."}
        return if(cnt>=2) {
            val diff = date.zipWithNext().map { it.second.toLong() - it.first.toLong() }
            "${cnt}|${date[0]},${diff.joinToString(",")}|${temp.joinToString(",")}"
        } else if(cnt==1) {
            "${cnt}|${date[0]}|${temp[0]}"
        } else {
            "${cnt}|0|0"
        }
    }

    // 두 시계열을 더한다
    operator fun plus(o: EntTempInfo): EntTempInfo =
        if(this.cnt==0 && o.cnt==0) {
            EntTempInfo().also {
                it.cnt = 0;
                it.date = mutableListOf()
                it.temp = mutableListOf()
            }
        } else if(this.cnt==0 && o.cnt>0) {
            EntTempInfo().also {
                it.cnt = o.cnt;
                it.date = ArrayList(o.date)
                it.temp = ArrayList(o.temp)
            }
        } else if(this.cnt>0 && o.cnt==0) {
            EntTempInfo().also{
                it.cnt = cnt;
                it.date = ArrayList(date)
                it.temp = ArrayList(temp)
            }
        } else {
            EntTempInfo().also{
                require(date[cnt-1] < o.date[0]){"원 시퀀스의 마지막 시간이 추가되는 시퀀스의 시간보다 앞서야 합니다."}
                it.cnt = cnt+o.cnt;
                it.date = ArrayList(date)
                it.date.addAll(o.date)
                it.temp = ArrayList(temp)
                it.temp.addAll(o.temp)
            }
        }
}