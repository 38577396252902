package app

enum class EntTempInfoViewType(val key:String){
    ONCE("once"),
    LIST("list");
}
enum class EnumTempInfoEvent(val code: Int) {
    NORMAL(0),
    EVENT(1);

    companion object {
        operator fun invoke(code:Int) = values().find{it.code==code} ?: throw InvalidTempInfoEvent(code)
    }
    class InvalidTempInfoEvent(val code: Int): EntTempInfoLog.TempInfoLogException()
}

enum class EnumTempInfoButton(val code: Int) {
    NOBUTTON(0),
    START(29),
    END(25);

    companion object {
        operator fun invoke(code:Int) = values().find{it.code==code} ?: throw InvalidTempInfoButton(code)
    }
    class InvalidTempInfoButton(val code: Int): EntTempInfoLog.TempInfoLogException()
}

enum class EnumTempInfoButtonAction(val code: Int) {
    ACTION(1),
    NOACTION(0);

    companion object {
        operator fun invoke(code:Int) = values().find{it.code==code} ?: throw InvalidTempInfoButtonAction(code)
    }
    class InvalidTempInfoButtonAction(val code: Int): EntTempInfoLog.TempInfoLogException()
}