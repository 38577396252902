package chart

import Factory
import app.*
import ein2b.core.entity.eEntity
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

external val AppChart:dynamic
// language=html
private val factory = Factory.html("""
    <section style="position:relative">    
        <div data-view="chart"></div>
        <div data-view="nodata" class="info-red" style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)">온도 기록 없음</div>
    </section>
""".trimIndent())
suspend fun ChartView() = eView(factory){ rootView->
    val chartId = Chart.id
    rootView.sub("chart").className = chartId
    val nodataView = rootView.sub("nodata"){ it.displayNone() }
    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            AppChart.api()
            var intervalId = -1
            val charApiEnd = {
                if(AppChart.isApiEnd() as Boolean){
                    if(AppChart.resData() != null){
                        val resData = AppChart.resData() as String
                        val width = window.innerWidth - 20
                        val height = window.innerHeight
                        var isNoData = false
                        when(AppChart.chartType()){
                            EntTempInfoViewType.ONCE.key-> eEntity.parse(EntChartOnce(), resData)?.also{ res->
                                Chart.tempInfoGraph(chartId, "", res.tempInfo, width, height)
                                isNoData = res.tempInfo.cnt == 0
                            }
                            EntTempInfoViewType.LIST.key-> eEntity.parse(EntChartList(), resData)?.also{ res->
                                Chart.tempInfoGraph(chartId, SensorTempInfoList().also{
                                    it.deliveryStartDate = res.startDate
                                    it.deliveryEndDate = res.endDate
                                    it.list = res.tempInfoList.map{ tempInfo->
                                        SensorTempInfoList.SensorTempInfo().also{ sensorTempInfo->
                                            sensorTempInfo.sensorCode = ""
                                            sensorTempInfo.tempInfo = tempInfo
                                        }
                                    }.toMutableList()
                                }, width, height, isSensorCodeShow = false)
                                isNoData = res.tempInfoList.isEmpty() || res.tempInfoList.sumOf{ it.cnt } == 0
                            }
                        }
                        if(isNoData) nodataView.displayInlineBlock() else nodataView.displayNone()
                    }
                    window.clearInterval(intervalId)
                }
            }
            intervalId = window.setInterval(charApiEnd, 100)
        }
    })
}