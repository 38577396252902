package app

import ein2b.core.core.uuid
import ein2b.core.entity.eEntity

external fun makeGraph(d:String):dynamic
class SensorTempInfoList: eEntity(){
    class SensorTempInfo: eEntity(){
        var sensorCode:String by string(::sensorCode)
        var tempInfo: EntTempInfo by entity(::tempInfo, ::EntTempInfo)
    }
    var itemConditionMinMax: MutableList<Float> by floatList(::itemConditionMinMax){ default(mutableListOf(), false) }
    var deliveryStartDate:String by string(::deliveryStartDate){ default("", false) }
    var deliveryEndDate:String by string(::deliveryEndDate){ default("", false) }
    var list: MutableList<SensorTempInfo> by entityList(::list, ::SensorTempInfo)
}
private class ChartData: eEntity(true){
    class Item: eEntity(true){
        var sensorCode:String by string(::sensorCode)
        var date: MutableList<String> by stringList(::date)
        var temp: MutableList<Float> by floatList(::temp)
    }
    var isSensorCodeShow: Boolean by bool(::isSensorCodeShow)
    var width: Int by int(::width)
    var height: Int by int(::height)
    var selector:String by string(::selector)
    var itemConditionMinMax: MutableList<Float> by floatList(::itemConditionMinMax){ default(mutableListOf(), false) }
    var deliveryStartDate:String by string(::deliveryStartDate)
    var deliveryEndDate:String by string(::deliveryEndDate)
    var list: MutableList<Item> by entityList(::list, ::Item)
}
object Chart{
    val id get() = "chart_${uuid("")}"
    fun tempInfoGraph(selector:String, sensorCode:String, tempInfo:EntTempInfo, width:Int = 1600, height:Int = 700){
        tempInfoGraph(selector, SensorTempInfoList().also{
            it.list = mutableListOf(
                SensorTempInfoList.SensorTempInfo().also{ sensorTempInfo->
                    sensorTempInfo.sensorCode = sensorCode
                    sensorTempInfo.tempInfo = tempInfo
                }
            )
        }, width, height, false)
    }
    fun tempInfoGraph(selector:String, entity:SensorTempInfoList, width:Int = 1200, height:Int = 700, isSensorCodeShow:Boolean = true){
        val chartData = ChartData().also{
            it.isSensorCodeShow = isSensorCodeShow
            it.width = width
            it.height = height
            it.selector = ".$selector"
            it.itemConditionMinMax = entity.itemConditionMinMax
            it.deliveryStartDate = entity.deliveryStartDate
            it.deliveryEndDate = entity.deliveryEndDate
            it.list = entity.list.map{ sensorTempInfo->
                ChartData.Item().also{ tempInfo->
                    tempInfo.sensorCode = sensorTempInfo.sensorCode
                    tempInfo.temp = sensorTempInfo.tempInfo.temp.map{ v-> v.toFloat() / 100 }.toMutableList()
                    tempInfo.date = sensorTempInfo.tempInfo.date.map{ d-> EntTempInfo.DateFormat(d).toDate() }.toMutableList()
                }
            }.toMutableList()
        }

        val v = chartData.stringify()
        makeGraph(v)
    }
}